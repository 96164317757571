import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Container, Box, Heading, HomepageImage, Flex } from "../components/ui"
import * as styles from "../components/about-hero.css"
import './Form.css';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

interface PageProps {
  data: {
    page: {
      id: string
      title: string
      slug: string
      description: string
      image?: HomepageImage
      html: string
    }
  }
}

export default function Page(props: PageProps) {
  const { page } = props.data
  const { image } = props.data.page;
  return (
    <Layout {...page}>
      <Container >
        <Flex variant="wrap">

          <Box width="third">
            <Heading as="h1" center>{page.title}</Heading>
            <div className={styles.justified}
              dangerouslySetInnerHTML={{
                __html: page.html,
              }}
            />
            {page.slug === "mailing-list" || page.slug == "purchase" && (
              <div className="container">
                <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact">
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value="contact" />
                  <label>
                    Name
                    <input type="text" name="name" id="name" required />
                  </label>
                  <label>
                    Email
                    <input type="email" name="email" id="email" required />
                  </label>
                  <label>
                    Postcode
                    <input type="postcode" name="postcode" id="postcode" required />
                  </label>
                  <label>
                    Message
                    <textarea name="message" id="message" rows={5} required />
                  </label>
                  <input type="submit" value="Submit" />
                  <input type="reset" value="Clear" />
                </form>
              </div>

            )}
          </Box>

          {image && <Box width="half" className={styles.fitPage}>

            <GatsbyImage
              className={styles.pageImage}
              alt={image.alt}
              image={getImage(image.gatsbyImageData)}
            />

          </Box>}

        </Flex>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query PageContent($id: String!) {
    page(id: { eq: $id }) {
      id
      title
      slug
      description
      image {
        id
        gatsbyImageData
        alt
      }
      html
    }
  }
`
